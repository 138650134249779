<template>
    <div>
        <div class="head-container">
            <div>
                <div class="head-text-main">
                    Ερευνητικά Έργα
                </div>
            </div>
        </div>
        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    <h2 class="mt-8 mb-4"><strong>Η δημιουργία του DSS NUTRISENSE υποστηρίχθηκε από τα ακόλουθα ερευνητικά έργα:</strong></h2>
                    <p>
                        “<strong>NUTRISENSE</strong> Ανάπτυξη προηγμένης τεχνολογίας με χρήση εκλεκτικών ιοντικών ηλεκτροδίων και κατάλληλο λογισμικό για υδροπονικές καλλιέργειες κηπευτικών με έμφαση στην ανακύκλωση των απορροών σε κλειστά συστήματα”. Το έργο χρηματοδοτείται από το Ελληνικό Ίδρυμα Έρευνας και Καινοτομίας
                    </p>

                    <p>
                        “<strong>VEGADAPT</strong> Βελτίωση της προσαρμοστικότητας μεσογειακών λαχανοκομικών καλλιεργειών σε συνθήκες πολλαπλής καταπόνησης οφειλόμενης στην κλιματική αλλαγή”. Το έργο χρηματοδοτείται από το Ίδρυμα Σύμπραξης για την Έρευνα και την Καινοτομία στη Μεσόγειο (Partnership on Research and Innovation in the Mediterranean Area - PRIMA), (https://www.veg-adapt.unito.it). (<a href="https://www.veg-adapt.unito.it" target="_blank">https://www.veg-adapt.unito.it</a>).
                    </p>
                    <p>
                        “<strong>ECONUTRI</strong>. Καινοτόμες ιδέες και τεχνολογίες για την οικολογικά βιώσιμη διαχείριση των θρεπτικών συστατικών στη γεωργία, με στόχο την πρόληψη, τον μετριασμό και την εξάλειψη της ρύπανσης στο έδαφος, το νερό και τον αέρα”. Το έργο χρηματοδοτείται από την Ευρωπαϊκή Επιτροπή στα πλαίσια του HORIZON Europe (<a href="https://econutri-project.eu/" target="_blank">https://econutri-project.eu/</a>).
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.heading {
    color: #5FA936;
    font-size: 36px;
    font-weight: 700;
}

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 80px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    color: #646464;
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 65vw;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
ol {
    color: #383838;
    font-weight: bold;
}
.spacer {
    display: inline-block;
    width: 40px;
}
strong {
    color: #383838;
}
</style>
